import { writable } from "svelte/store";

export const recommended_titles = writable([]);
export const recommended_titles_index = writable(null);
export const keywords_store = writable([]);
export const selected_streamers = writable([]);
export const startup_state = writable(true);
export const animation_finished = writable(false);
export const current_movie = writable("");
export const loading_keywords = writable(false);
export const is_tag_array = writable();
export const current_year = new Date().getFullYear();
export const call_reduce_search = writable(false);
export const call_collapse_moods = writable(false);
export const call_get_titles = writable(false);
export const call_get_random_prompt = writable(false);
// export const all_posters_loaded = writable(false);  // Activate for automatically retrieving movie.getDetails

export const initialSettings = {
  selected_locale: "",
  production_period: [1895, current_year],
  subtitle_languages: [],
  audio_languages: [],
  region: "CH",
  consent_necessary_cookies: false,
  consent_ad_cookies: false
};
export const settings_store = writable({ ...initialSettings });
export const random_button_initiated = writable(true); // when the user visits for the first time, the bubble for the magic random search button should appear
export const consentModalOpen = writable(false);
export const service_name = "Trovio";
