<script>
  export let source = "";
  export let isOpen = false;

  import { createEventDispatcher } from "svelte";
  import SvelteMarkdown from "svelte-markdown";

  const dispatch = createEventDispatcher();

  function closeModal() {
    isOpen = false;
    dispatch("close"); //custom event to trigger call of closeDetailModal() in parent component
  }
</script>

<!-- ---------------------------------------------------------------------- -->

{#if isOpen}
  <div class="md-modal-container">
    <div class="md-content">
      <div>
        <button class="close-button" on:click={closeModal} on:keypress={closeModal} />
      </div>
      <SvelteMarkdown {source} />
    </div>
  </div>
{/if}

<!-- ---------------------------------------------------------------------- -->

<style>
  .md-modal-container {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: var(--black);
  }
  .md-content {
    width: 80%;
    height: 90%;
    overflow: auto;
    position: relative;
    margin-top: 10px;
    background-color: var(--white);
    border-radius: 24px;
    /* padding: 2em 5em 5em 5em; */
    padding: 2em 5% 5% 5%;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    line-height: 160%;
  }
  /* the global selector is needed to style the h1 tag inside the markdown */
  .md-content :global(h1) {
    color: var(--solid-blue);
    font-weight: 300;
    margin-bottom: 1.5em;
    line-height: 120%;
  }
  .md-content :global(h2) {
    margin-top: 2em;
    font-weight: 300;
  }
  .md-content :global(h3) {
    margin-top: 2em;
    font-weight: 300;
  }
  .close-button {
    background: url(/buttons/btn_close_details.svg) no-repeat;
    width: 48px;
    height: 48px;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 2010;
  }
  .close-button:hover {
    background: url(/buttons/btn_close_details_hover.svg) no-repeat;
  }
</style>
